@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  /* pointer-events: none; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.custom-link {
  color: #025E84; 
  text-decoration: none; 
}

.custom-link:hover {
  text-decoration: underline; 
}

.custom-link:active {
  text-decoration: underline;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.btn {
  background-color: #3D405B !important;
  color: white !important;
  border: none !important;
}


.bg-myColor {
  background-color: #004D40;
  background-image: none;
}

.myImage{
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}